
 
  // define the initial state of the signup store
  const initialState = {
    userCartItems:'',
    isGetProducts:false,
    isLoader: true
  };
  
  // define how action will change the state of the store
  export const UserProfileReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'DELETE_PRODUCT':
        return {
          ...state,
          userCartItems: action?.payload?.data?.data,
          isGetProducts: true,
          isLoader: false
        };
      case 'UPDATE_CART':
        return {
          ...state,
          addProductToCart: action?.payload?.data?.data,
          isAddProduct: true,
          isLoader: false
        };
      case 'CLEAR_CART_PRODUCTS':
        return {
          ...state,
          addProductToCart: action?.payload?.data?.data,
          isAddProduct: true,
          isLoader: false
        };
      case 'GET_CART_PRODUCTS':
        return {
          ...state,
          userCartItems: action?.payload?.data?.data,
          isGetProduct: true,
          isLoader: false
        };
      case 'GET_ERROR_TO_FETCH':
        return {
            userCartItems: action?.payload?.message,
            isGetProducts: false
        };
      default:
        return state;
    }
  }