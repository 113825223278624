import {takeEvery, call, put} from "redux-saga/effects";
import * as ProductCtg from "./ProductsCategoryAction";

function* getProductsList(){
    try {
        const result = yield call(ProductCtg.getProducts);
        if(result?.data.errorCode === '00'){
        yield put({type: 'GET_PRODUCTS', payload: result});
        } else yield put({type: 'GET_ERROR_TO_FETCH', payload: result})
    } catch (e){
        console.log(e);
        yield put({type: 'GET_ERROR_TO_FETCH', payload: e})
    }
}


function* ProductsCategorySaga(){
yield takeEvery('GET_PRODUCT_LIST', getProductsList)
}

export default ProductsCategorySaga;