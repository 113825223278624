import {takeEvery, call, put} from "redux-saga/effects";
import axios from "axios";
import { getCookie,keyPie } from "../common/Helpers";
import {AES,enc} from 'crypto-js';

// API Functions 
const clearCarts = (prdct) => {
    const userId = getCookie('iValueOne')||'';
    const decUserId = AES.decrypt(userId,keyPie());
    const payload ={
       "userId":decUserId.toString(enc.Utf8)
    }
    return axios.delete("https://fireapicalls.govindmukund.com/clearCartItems",{data:payload});
};

const deleteProducts = (prdct) => {
    const userId = getCookie('iValueOne')||''
    const decUserId = AES.decrypt(userId,keyPie())
    const payload ={
       "userId":decUserId.toString(enc.Utf8),
       "productId":prdct?.data
    }
    return axios.delete("https://fireapicalls.govindmukund.com/deleteCartProducts",{data:payload});
};
   
const updateItmToCart = (prdct) => {
    const userId = getCookie('iValueOne')||''
    const decUserId = AES.decrypt(userId,keyPie())
    const payload ={
       "userId":decUserId.toString(enc.Utf8),
       "productId":prdct?.data?.PrdId,
       "operate":prdct?.data?.Oprt,
       "quantity":prdct?.data?.Quant

    }
    return axios.put("https://fireapicalls.govindmukund.com/cartproductIncDec",payload);
};

const getUserCartList = (prdct) => {
    const userId = getCookie('iValueOne')||''
    const decUserId = AES.decrypt(userId,keyPie())
    const payload ={
       "userId":decUserId.toString(enc.Utf8)
    }
    return axios.put("https://fireapicalls.govindmukund.com/products/getCartList",payload);
};

//Generator functions

function* deleteItemFromCat(porp){
    try {
        const result = yield call(deleteProducts,porp);
        if(result?.data.errorCode === '00'){
        yield put({type: 'DELETE_PRODUCT', payload: result});
        } else yield put({type: 'GET_ERROR_TO_FETCH', payload: result})
    } catch (e){
        console.log(e);
        yield put({type: 'GET_ERROR_TO_FETCH', payload: e})
    }
};


function* updateCartsItem(prot){
    const auth = getCookie('IsLoggined');
    try {
        if(auth){
            const result = yield call(updateItmToCart,prot);
            if(result?.data.errorCode === '00'){
            yield put({type: 'UPDATE_CART', payload: result});
            // window.location = '/mycart';
            // yield call(HomeAction.navigateUrls,"/mycart");
            } else yield put({type: 'GET_ERROR_TO_FETCH', payload: result})
        };
            
    } catch (e){
        console.log(e);
        yield put({type: 'GET_ERROR_TO_FETCH', payload: e})
    }
}

function* getCartList(prot){
    const auth = getCookie('IsLoggined');
    try {
        if(auth){
            const result = yield call(getUserCartList,prot);
            if(result?.data.errorCode === '00'){
            yield put({type: 'GET_CART_PRODUCTS', payload: result});
            // window.location = '/mycart';
            // yield call(HomeAction.navigateUrls,"/mycart");
            } else yield put({type: 'GET_ERROR_TO_FETCH', payload: result})
        } 
            
    } catch (e){
        console.log(e);
        yield put({type: 'GET_ERROR_TO_FETCH', payload: e})
    }
}

function* clearCartItm(prot){
    const auth = getCookie('IsLoggined');
    try {
        if(auth){
            const result = yield call(clearCarts,prot);
            if(result?.data.errorCode === '00'){
            yield put({type: 'CLEAR_CART_PRODUCTS', payload: result});
            // window.location = '/mycart';
            // yield call(HomeAction.navigateUrls,"/mycart");
            } else yield put({type: 'GET_ERROR_TO_FETCH', payload: result})
        } 
            
    } catch (e){
        console.log(e);
        yield put({type: 'GET_ERROR_TO_FETCH', payload: e})
    }
}

function* HomeComponentSaga(){
yield takeEvery('DELET_PRODUCT', deleteItemFromCat)
yield takeEvery('CLEAR_CART', clearCartItm)
yield takeEvery('GET_USER_CART', getCartList)
yield takeEvery('UPDATE_PRODUCT',updateCartsItem)
}

export default HomeComponentSaga;