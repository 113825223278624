import {
    CREATE_USER_SUBMITTED,
  } from "./constant";
  
  // define the initial state of the signup store
  const initialState = {
    usernameError: "",
    passwordError: "",
    isSubimtted: false
  };
  
  // define how action will change the state of the store
  export const signupReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_USER_SUBMITTED:
        return {
          ...state,
          isSubimtted: true
        };
     
      default:
        return state;
    }
  }