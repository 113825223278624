import React from "react";
import { CirclesWithBar } from 'react-loader-spinner';

const Loader =()=>{

    return (<>
    <CirclesWithBar
        height="100"
        width="100"
        color="grey"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        outerCircleColor="grey"
        innerCircleColor="skyblue"
        barColor="grey"
        ariaLabel='circles-with-bar-loading'
    />
    </>)
}

export default Loader;