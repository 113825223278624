import Button from 'react-bootstrap/Button';
import { MainFooterContainer } from './headFootStyle';
import * as Icon from 'react-bootstrap-icons';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getCookie } from '../../Pages/common/Helpers';


function Footer() {
  const isAuthenticated = getCookie('IsLoggined');
  return (
    <>
    <MainFooterContainer className='container-fluid p-2 text-dark footers'>
      <Row className='d-flex mt-1 justify-content-center'>
      <div className='col-4'></div>
        <div className='col-4' style={{display:'contents',fontSize: '150%'}}>
        <a href="" className="text-primary me-4">
        <Icon.Facebook />
        </a>
        <a href="" className="text-danger me-4">
        <Icon.Instagram />
        </a>
        <a href="" className="text-primary me-4">
        <Icon.Twitter />
        </a>
        <a href="" className="text-danger me-4">
        <Icon.Youtube />
        </a>
        <a href="" className="text-info me-4">
        <Icon.Linkedin />
        </a>
        </div>
        <div className='col-4'></div>
        </Row>
            <Row className='mt-4'>
            <div className='col-3'></div>
            <div className='col-6'>
                <span className=''>
                  <strong>Sign up for our newsletter</strong>
                </span>
                <input contrast type='email' className='InputField' placeholder='Email address' />
                <Button className="text-danger btn-info" style={{background: 'rgba(0, 0, 0, 0)',marginTop: '-7px', border: 'none'}}>
                  Subscribe
                </Button>
              </div>
              <div className='col-3'></div>
            </Row>

        <Row className='p-4 justify-content-center'>
          <Col>
          <p>!! Success is like a locked door, to open and get it through, you need to create your own key !! Let the people discover you. You might have the key of the locked doors in their lives! Open yourself to the world; you might be the magic the world needs.</p>
          </Col>
        </Row>

          <Row className='p-1'>
          <div className='col-3'></div>
          <div className='col-3 ml-2' style={{textAlign: 'center'}}>
              <h5 className='text-uppercase fw-bold mb-4'>Useful Links</h5>

              <ul className='list-unstyled mb-0'>
                {!isAuthenticated && <li className='mb-3'>
                  <Link to='/' style={{textDecoration: 'none'}} className='text-dark'>
                  Sign up
                  </Link>
                </li>}
                <li className='mb-3'>
                  <Link to='/' style={{textDecoration: 'none'}} className='text-dark'>
                  About
                  </Link>
                </li>
                <li className='mb-3'>
                  <Link to='/' style={{textDecoration: 'none'}} className='text-dark'>
                  Careers
                  </Link>
                </li>
              </ul>
              </div>

              <div className='col-3' style={{textAlign: ''}}>
              <h5 className='text-uppercase fw-bold mb-4'>Contact</h5>
              <p>
              <Icon.HouseDoor className='me-3' />
                NOIDA, UP 201302, BHARAT
              </p>
              <p>
                <Icon.Envelope className='me-3' />
                enquiry@govindmukund.com
              </p>
              <p>
                <Icon.Phone className='me-3' /> + 91 9934469842
              </p>
              <p>
                <Icon.Whatsapp className='me-3' /> + 91 9934469842
              </p>
            </div>

              <div className='col-3'></div>
          </Row>
        
        <Row className='p-2'>
        <div className='col-1'></div>
        <div className='col-10 policyA'>
        © 2024 Copyright: <a className='text-dark' target="_blank" title="T&C PDF" href='https://fireimages.govindmukund.com/PolicyPDF/website_tnc.pdf'>
          Term & Conditions
        </a> &nbsp;
        <a className='text-dark' target="_blank" title="privacyPolicy PDF" href='https://fireimages.govindmukund.com/PolicyPDF/website_privacyPolicy.pdf'>
          Privacy Policy
        </a>&nbsp;
        <a className='text-dark ml-2 CTA' href="https://fireimages.govindmukund.com/PolicyPDF/website_refundPolicy.pdf" target="_blank" title="refundPolicy PDF" >Refund Policy</a>&nbsp;
        <a className='text-dark ml-2 CTA' href="https://fireimages.govindmukund.com/PolicyPDF/website_shopDelivery.pdf" target="_blank" title="shopDelivery PDF" >Delivery Policy</a>

        </div>
        <div className='col-1'></div>
        </Row>
      </MainFooterContainer>
    </>
  );
}

export default Footer;