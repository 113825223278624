import React from "react";
import { Navigate,Outlet } from "react-router-dom";
import { getCookie } from "../../Pages/common/Helpers";

const PrivateComponent=()=>{
    const auth = getCookie('IsLoggined');
    const userIde = getCookie('iValueOne');
    const fnam = getCookie('iValueTwo');
    return (fnam && userIde && auth)?<Outlet/>:<Navigate to="/signIn"/>
}

export default PrivateComponent;