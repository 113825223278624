import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { signupReducer } from "../signUp/signupReducer";
import { signinReducer } from "../signIn/signinReducer";
import { HomeCompReducer } from "../homeBody/HomeComponentReducer";
import {UserProfileReducer} from "../userProfiles/userProfileReducer";
import {ProductsCategoryReducer} from "../ProductsCategory/ProductsCategoryReducer";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    signupReducer,
    signinReducer,
    HomeCompReducer,
    UserProfileReducer,
    ProductsCategoryReducer
  });

export default createRootReducer;