
import './App.css';
import Footer from './Components/headAndFoot/Footer';
import Routers from './Components/Common/Routers';
import { BrowserRouter } from 'react-router-dom';
import Headers from "./Pages/headAndFoot/headerContainer"
import Root from './Pages/common/Root';

function App() {
  return (<Root><BrowserRouter>
    <Headers/>
      <Routers />
      <Footer />
      </BrowserRouter></Root>
  );
}

export default App;
