import {takeEvery, call, put} from "redux-saga/effects";
import * as SignUpAct from "../signUp/signUpActions"

function* addNewUser(){
const result = yield call(SignUpAct.signupNewUser())
}


function* signupSaga(){
yield takeEvery('CREATE_USER_SUBMITTED', addNewUser)
}

export default signupSaga;