import { connect } from "react-redux";
import HeaderComp from "../../Components/headAndFoot/Header";
import { bindActionCreators } from "redux";
import * as SignInAction from "../signIn/actions";

const mapStateToProps = (state)=>{
const signInValue = state.signinReducer;
const cartItm = state.HomeCompReducer;
return {
val: 'HeadContainer',
isAuthenticated: signInValue?.isAuthenticated,
user: signInValue?.user,
cartList:cartItm?.userCartItems?.cartItm,
totlCartVal:cartItm?.userCartItems?.totalCartValue
 }
}

const mapDispatchToProps = (dispatch)=>{
    return bindActionCreators(SignInAction,dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(HeaderComp);