
 
  // define the initial state of the signup store
  const initialState = {
    getProducts: "",
    isGetProducts:false,
    isLoader: true
  };
  
  // define how action will change the state of the store
  export const ProductsCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_PRODUCTS':
        return {
          ...state,
          getProducts: action?.payload?.data?.data,
          isGetProducts: true,
          isLoader: false
        };
      case 'GET_ERROR_TO_FETCH':
        return {
            getProducts: action?.payload?.message,
            isGetProducts: false
        };
      
      default:
        return state;
    }
  }