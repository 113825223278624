import {takeEvery, call, put} from "redux-saga/effects";
import axios from "axios";
import { getCookie,keyPie } from "../common/Helpers";
import {AES,enc} from 'crypto-js';
import * as HomeAction from "../homeBody/HomeComponentAction";

// API Functions 
const getProducts = () => {
    return axios.get("https://fireapicalls.govindmukund.com/products/getProductList");
};
   
const addItmToCart = (prdct) => {
    const userId = getCookie('iValueOne')||''
    const decUserId = AES.decrypt(userId,keyPie())
    const payload ={
       "userId":decUserId.toString(enc.Utf8),
       "productId":prdct?.data?._id,
       "quantity":1
    }
    return axios.post("https://fireapicalls.govindmukund.com/addToCart",payload);
};

const getUserCartList = (prdct) => {
    const userId = getCookie('iValueOne')||''
    const decUserId = AES.decrypt(userId,keyPie())
    const payload ={
       "userId":decUserId.toString(enc.Utf8)
    }
    return axios.put("https://fireapicalls.govindmukund.com/products/getCartList",payload);
};

//Generator functions

function* getProductsList(){
    try {
        const result = yield call(getProducts);
        if(result?.data.errorCode === '00'){
        yield put({type: 'GET_PRODUCT', payload: result});
        } else yield put({type: 'GET_ERROR_TO_FETCH', payload: result})
    } catch (e){
        console.log(e);
        yield put({type: 'GET_ERROR_TO_FETCH', payload: e})
    }
};


function* addProductsToCart(prot){
    const auth = getCookie('IsLoggined');
    try {
        if(auth){
            const result = yield call(addItmToCart,prot);
            if(result?.data.errorCode === '00'){
            yield put({type: 'ADD_PRODUCTS', payload: result});
            alert('Items added successfully to your cart');
            } else yield put({type: 'GET_ERROR_TO_FETCH', payload: result})
        };
            
    } catch (e){
        console.log(e);
        yield put({type: 'GET_ERROR_TO_FETCH', payload: e})
        alert('Items NOT added');
    }
}

function* getCartList(prot){
    const auth = getCookie('IsLoggined');
    try {
        if(auth){
            const result = yield call(getUserCartList,prot);
            if(result?.data.errorCode === '00'){
            yield put({type: 'GET_CART_PRODUCTS', payload: result});
            // window.location = '/mycart';
            // yield call(HomeAction.navigateUrls,"/mycart");
            } else yield put({type: 'GET_ERROR_TO_FETCH', payload: result})
        } 
            
    } catch (e){
        console.log(e);
        yield put({type: 'GET_ERROR_TO_FETCH', payload: e})
    }
}


function* HomeComponentSaga(){
yield takeEvery('GET_PRODUCTS', getProductsList)
yield takeEvery('ADD_PRODUCT', addProductsToCart)
yield takeEvery('GET_USER_CART', getCartList)
}

export default HomeComponentSaga;