import React from "react";
import signupSaga from "../signUp/signupSaga"
import signInSaga from "../signIn/signInSaga"
import HomeComponentSaga from "../homeBody/HomeComponentSaga";
import ProductsCategorySaga from "../ProductsCategory/ProductsCategorySaga";
import userProfileSaga from '../userProfiles/userProfileSaga';
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import {  ConnectedRouter } from "connected-react-router";

import rootReducer from "./RootReducer";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";

const Root = ({ children = {} }) => {
  const history = createBrowserHistory();
  const sagaMiddleware = createSagaMiddleware();

    const store = configureStore(
    {
      reducer: rootReducer(history),
      middleware: ()=>[sagaMiddleware]
    }
  );

  sagaMiddleware.run(signupSaga);
  sagaMiddleware.run(signInSaga);
  sagaMiddleware.run(HomeComponentSaga);
  sagaMiddleware.run(ProductsCategorySaga);
  sagaMiddleware.run(userProfileSaga);
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>{children}</ConnectedRouter>
    </Provider>
  );
};

export default Root;