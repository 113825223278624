import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import styled from "styled-components";
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Badge from 'react-bootstrap/Badge';
import Overlay from 'react-bootstrap/Overlay';
import Toast from 'react-bootstrap/Toast';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Pic from '../../TempPictures/devanagri.JPG';
import * as Icon from 'react-bootstrap-icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCookie, setCookie, keyPie } from '../../Pages/common/Helpers';
import { AES, enc } from 'crypto-js'

const myCartWrap = styled.div`
width: 20%;
margin: 1%;
border: 1px solid gray;
background-color: wheat;
`;


function Header(props) {
  const userIde = getCookie('iValueOne') || '';
  const isSign = getCookie('IsLoggined') || '';
  const fname = getCookie('iValueTwo') || '';
  const dfname = AES.decrypt(fname, keyPie());
  const fName = dfname.toString(enc.Utf8);
  const isAuthenticated = (userIde && isSign && fname) ? true : false;
  let itemsTotalOnly = 0;

  const { cartList } = props;
  { cartList?.map((prd, i) => { itemsTotalOnly += (cartList[i]?.price * cartList[i]?.quantity); }) }
  const navigate = useNavigate();
  const logout = () => {
    setCookie('IsLoggined', '', 0);
    setCookie('iValueTwo', '', 0);
    setCookie('iValueOne', '', 0);
    navigate('/');
  }
  const [isDisplayBlock, setIsDiplayBlock] = useState(false)

  return (
    <div className='headers'>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container className="container-fluid">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav style={{ display: 'block' }}><Icon.Whatsapp className="larger text-success" /> (+91) 9934469842</Nav>
            <Nav style={{ marginLeft: '60%' }}>
              <NavDropdown className='d-flex' title="Account" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/myacc">My Account</NavDropdown.Item>
                <NavDropdown.Item href="/mycart">My Cart</NavDropdown.Item>
                <NavDropdown.Item href="/myorder">My Order</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={isAuthenticated ? logout : ''} href={isAuthenticated ? "/" : "/signUp"}>
                  {isAuthenticated ? '| Logout' : 'Sign up'}
                </NavDropdown.Item>
              </NavDropdown>
              <Nav className="p-2">{(fName || props?.user?.fName) && isAuthenticated ? <a className='text-dark' style={{ textDecoration: 'none' }} href="/">Welcome: {fName || props?.user?.fName}</a> : <a className='text-dark' style={{ textDecoration: 'none' }} href="/signIn">Log in</a>} &nbsp;&nbsp;{isAuthenticated ? <a className='text-dark ml-2' style={{ textDecoration: 'none' }} href="/" onClick={logout}>| Logout</a> : <a className='text-dark ml-2' style={{ textDecoration: 'none' }} href="/signUp" >Sign up</a>}</Nav>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navbar bg="danger" variant="dark">
        <Container fluid>
          <Navbar.Brand href="#home">
            <img
              src="https://fireimages.govindmukund.com/images/doorLocks.png"
              width="111%"
              height="40px"
              className=""
              alt="devanagri"
            />

          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown className='' title="Shop" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/3d">3D/3D+ Doors</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/clasic">Classic</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/2d">2D Doors</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          {isAuthenticated && <Nav style={{ display: 'block', marginRight: '1%' }}><a className='text-dark ml-2' style={{ textDecoration: 'none' }} onMouseOver={() => setIsDiplayBlock(true)}><Icon.BagHeartFill fontSize={'xx-large'} className="text-light" /></a></Nav>}


          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
          </Form>
        </Container>
      </Navbar>
   
      <ToastContainer position="" className="p-3 top-10 end-0">
        <Toast onClose={() => setIsDiplayBlock(false)} bg='secondary' show={isDisplayBlock} delay={5000} autohide >
          <Toast.Header>

            <strong className="me-auto">Cart Summary <Icon.Cart /></strong>
            <small style={{ marginRight: '11%' }} >Items: {cartList?.length}</small>
            <small>Total: &#x20B9; {itemsTotalOnly} /- </small>
          </Toast.Header>
          <Toast.Body>
            {cartList?.map((prd, i) => {

              return (
                <Row><Col><p><Icon.ArrowRight className="larger" /> {prd?.name}</p>
                </Col><Col><p>Price: &#x20B9;{prd?.price}</p>
                  </Col><Col><p>quantity: {prd?.quantity}</p>
                  </Col></Row>
              )
            })}
            <a className='text-dark ml-2' style={{ textDecoration: 'none', color: 'red' }} href="/mycart"><span className="text-muted">Go To Cart</span><Icon.ArrowRight className="larger" /> </a>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default Header;