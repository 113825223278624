import axios from "axios";

export const getProductsList = ()=>{
  return {
    type: 'GET_PRODUCT_LIST'
  }
}

export const addProduct = ()=>{
  return {
    type: 'ADD_TODO',
    data:'registerNewUser'
  }
}

export const searchProduct = (data)=>{
    return {
      type: 'ADD_TODO',
      data
    }
  }

export const getProducts = () => {
 return axios.get("https://fireapicalls.govindmukund.com/products/getProductList");
};