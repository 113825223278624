import axios from "axios";

export const registerNewUser = (data)=>{
  return {
    type: 'CREATE_USER_SUBMITTED',
    data
  }
}

export const deleteNewUser = ()=>{
  return {
    type: 'ADD_TODO',
    data:'registerNewUser'
  }
}

export const signupNewUser = userData => {
  const payload = {
    "password": userData?.password,
    "fName": userData?.firstName,
    "lName": userData?.lastName,
    "phone": userData?.phone,
    "email": userData?.email
}
  axios
    .post("https://fireapicalls.govindmukund.com/usersignup", payload)
    .then(response => {
    alert(response?.data?.msg);
    })
    .catch(error => {
      if (error.resposne) {
       console.log(error)
      }
    });
};