import {takeEvery, call, put, take} from "redux-saga/effects";
import * as SignInAct from "./actions";
import { setCookie } from "../common/Helpers";

function* loginUser(data){
    try{
        const result = yield call(SignInAct.getLoginedUserDetails,data);
        if(result.data.errorCode === '00'){
            setCookie('IsLoggined',true,1);
            yield put({type: 'GET_CURRENT_USER', payload: result});
        }
        
    } catch (e){
      console.log(e);
      yield put({type: 'GET_ERROR_TO_FETCH', payload: e})
    }
}


function* signinSaga(){
yield takeEvery('SET_CURRENT_USER', loginUser)
}

export default signinSaga;