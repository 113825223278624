import React,{lazy,Suspense,useEffect} from "react";
import { Route, Routes } from 'react-router-dom'
import Loader from "./Loader";
import PrivateComponent from "./PrivateComponent";
import withRouter from "./withRouter";

const HomeComp = lazy(()=> import('../../Pages/homeBody/HomeComponentContainer'));
const AboutComp = lazy(()=> import('../homeBody/AboutComponent'));

const SignInComp = lazy(()=> import('../../Pages/signIn/signInContainer'));
const SignUpComp = lazy(()=> import('../../Pages/signUp/signUpContainer'));

const GiftsComp = lazy(()=> import('../../Pages/ProductsCategory/GiftsContainer'));
const CakesComp = lazy(()=> import('../../Pages/ProductsCategory/CakesContainer'));
const PrefumesComp = lazy(()=> import('../../Pages/ProductsCategory/PrefumesContainer'));

const MyOrderComp = lazy(()=> import('../userProfiles/UserOrderComponent'));
const MyCartComp = lazy(()=> import('../../Pages/userProfiles/userCartContainer'));
const MyCheckoutComp = lazy(()=> import('../../Components/userProfiles/UserCheckoutComponent'));
const UserAccComp = lazy(()=> import('../userProfiles/UserAccComponent'));

const Routers = ()=>{
  
     return (
          <Suspense fallback={<Loader />}>
          <Routes>
          <Route exact path="/" element={<HomeComp />} />
          <Route path="/about" element={<AboutComp />} />
          <Route path="/3d" element={<CakesComp />} />
          <Route path="/2d" element={<GiftsComp />} />
          <Route path="/clasic" element={<PrefumesComp />} />
          <Route path="/signIn" element={<SignInComp />} />
          <Route path="/signUp" element={<SignUpComp />} />

          <Route element={<PrivateComponent />}>
          <Route path="/myorder" element={<MyOrderComp />} />
          <Route path="/mycart" element={<MyCartComp />} />
          <Route path="/myacc" element={<UserAccComp />} />
          <Route path="/checkout" element={<MyCheckoutComp />} />
          </Route>

          <Route path="/*" element={<h1 style={{fontSize:'90%'}}>No Page Found</h1> } />
          </Routes>
          </Suspense>

)};

export default withRouter(Routers);