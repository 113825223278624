import { getCookie } from "../common/Helpers";
import { SET_TOKEN,GET_CURRENT_USER, GET_ERROR_TO_FETCH, SET_CURRENT_USER, UNSET_CURRENT_USER } from "./constant";

const initialState = {
  isAuthenticated: false,
  user: {},
  token: ""
};

export const signinReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ERROR_TO_FETCH:
      return {
        ...state,
        isAuthenticated: false,
        token:'',
        user: action.payload.response?.data
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: getCookie('IsLoggined')||true,
        token:action.payload.data.authed,
        user: action.payload.data.data,
        code: action.payload.data
      };
    case UNSET_CURRENT_USER:
      return initialState;
    default:
      return state;
  }
};