import axios from "axios";


export const setSignInUser = (data)=>{
  return {
    type: 'SET_CURRENT_USER',
    data
  }
}



export const getLoginedUserDetails = (userData)=> {
  const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  const isValidEmail = emailRegex.test(userData?.data?.username);
  const payload = isValidEmail ? {
    "password": userData?.data?.password,
    "email": userData?.data?.username,
  } : {
    "password": userData?.data?.password,
    "phone": userData?.data?.username
  }
   return axios.post("https://fireapicalls.govindmukund.com/usersignin", payload);
    
};

