import styled from "styled-components";

export const MainFooterContainer = styled.div`
background: rgba(0, 0, 0, 0.2);

.policyA{
    display: flex;
    font-size: 65%;
    justify-content: space-around;
}
.InputField{
    background: rgba(0, 0, 0, 0);
  border: 1px solid;
  border-radius: 10px;
  margin: 2%;
    position: relative;
  color: #333;
}
`;