
 
  // define the initial state of the signup store
  const initialState = {
    getProducts: "",
    isGetProducts:false,
    isLoader: true
  };
  
  // define how action will change the state of the store
  export const HomeCompReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_PRODUCT':
        return {
          ...state,
          getProducts: action?.payload?.data?.data,
          isGetProducts: true,
          isLoader: false
        };
      case 'ADD_PRODUCTS':
        return {
          ...state,
          addProductToCart: action?.payload?.data?.data,
          isAddProduct: true,
          isLoader: false
        };
      case 'GET_CART_PRODUCTS':
        return {
          ...state,
          userCartItems: action?.payload?.data?.data,
          isGetProduct: true,
          isLoader: false
        };
      case 'GET_ERROR_TO_FETCH':
        return {
            getProducts: action?.payload?.message,
            isGetProducts: false
        };
      //   if (action.errorData.hasOwnProperty("username")) {
      //     errorState.usernameError = action.errorData["username"];
      //   }
      //   if (action.errorData.hasOwnProperty("password")) {
      //     errorState.passwordError = action.errorData["password"];
      //   }
      //   return errorState;
      // case CREATE_USER_SUCCESS:
      //   return {
      //     usernameError: "",
      //     passwordError: "",
      //     isSubimtted: false
      //   };
      default:
        return state;
    }
  }